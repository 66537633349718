import React from "react";
import BackIcon from "../../../../assets/svg/BackIcon";
import { MdInfo, MdEmail } from "react-icons/md";
import { CgPhone } from "react-icons/cg";
import { RiWhatsappFill } from "react-icons/ri";
import { RiTelegramFill } from "react-icons/ri";
// import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

import HomeContact from "../../../../assets/images/home-cont-icon.svg";
import PhoneContact from "../../../../assets/images/phone-cont-icon.svg";
import EmailContact from "../../../../assets/images/email-cont-icon.svg";
import ContactImg from "../../../../assets/images/3D-illustration/contact-us-3.png";
import { useSelector } from "react-redux";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";

const ContactUsBefore = () => {
  const { appDetails } = useSelector((state) => state.app);
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main contact_sec" id="payment_page">
        <div className=" container">
          <div className="d-flex justify-content-between mb-2">
            <h2 className="sectionTitle mb-0">Contact</h2>
            <a className="back-link" href="/">
              Back <BackIcon />
            </a>
          </div>

          <Row className="align-items-center justify-content-center">
            <Col lg={10} xl={8}>
              <div className="contact_info">
                <Row className="align-items-center">
                  <Col md={6}>
                    <h3 className="heading mb-4">Get In Touch With Us</h3>
                    <ul>
                      <li>
                        <div className="img_box">
                          <img src={HomeContact} alt="Home Icon" />
                        </div>
                        <div>
                          <h5>Our Location</h5>
                          {/* <p>Goa, India</p> */}
                        </div>
                      </li>
                      <li>
                        <a
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                              : "#"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          <div className="img_box">
                            <img src={PhoneContact} alt="Phone Icon" />
                          </div>
                          <div>
                            <h5>Phone Number</h5>
                            {/* <p>+91 7807496885</p> */}
                          </div>
                        </a>
                      </li>
                      <li>
                        <div className="img_box">
                          <img src={EmailContact} alt="Email Icon" />
                        </div>
                        <div>
                          <h5>Email Address</h5>
                          {/* <p>payment.7dayexch.com</p> */}
                        </div>
                      </li>
                    </ul>
                  </Col>
                  <Col
                    md={6}
                    className="d-none d-md-flex justify-content-center"
                  >
                    <div className="contact_img">
                      <img src={ContactImg} alt="Contact Image" />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </main>

      {isAuth ? <Footer /> : <BeforeLoginFooter />}
    </>
  );
};

export default ContactUsBefore;
